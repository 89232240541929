import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import Restore from './Restore';

function Clock({ tendancesPat, patientName, chosenName }) {

    const [heure, setHeure] = useState("");
    const [date, setDate] = useState("");
    const [isClicked, setIsClicked] = useState(false);
    const [stored, setStore] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);

    useEffect(
        () => {
            setInterval(() => {
                const n = new Date();
                setHeure(n.toLocaleString("fr-BE", { timeStyle: "medium" }))
                setDate(n.toLocaleString("en-GB", { dateStyle: "short" }))
            }, 1000)
        }
        , [])

    const restore = () => {
        setStore([])
        for (let i = 0; i < localStorage.length; i++) {
            const nameKey = localStorage.key(i)
            setStore(prevValue => {
                return [...prevValue, nameKey]
            })

        }
        setIsClicked(true)

    }

    const save = () => {
        localStorage.setItem(patientName, JSON.stringify(tendancesPat))
        setSnackOpen(true)
    }

    return (
        <>
            <AppBar
                sx={{
                    backgroundColor: "white",
                    marginTop: "30px",
                    borderTop: "2px solid #7121A3",
                    borderBottom: "2px solid #7121A3",
                }}
                elevation={0} >
                <Toolbar>
                    <Typography style={{ color: "#FF5F1F" }} variant="h6">{date} &nbsp;</Typography>
                    <Typography style={{ color: "#FF5F1F", flexGrow: 1 }} variant="h6"> {heure}</Typography>
                    <Typography style={{ color: "#411376", flexGrow: 1 }} variant="h5" >Anesthesia <a target="_blank" rel="noreferrer"  style={{textDecoration: "none", cursor:"text", color: "#411376"}} href="https://docs.google.com/spreadsheets/d/1_lDVSHsxd29bV0-gHhuq9wSQX_rOUe1mmQU1uakEY5k/edit?usp=sharing">P</a>rotocol</Typography>

                    <Stack spacing={1} direction="row">
                        <Button variant="contained" style={{ backgroundColor: "#FF5F1F", color: "white" }} onClick={restore}>Restore</Button>
                        <Button variant="contained" style={{ backgroundColor: "#FF5F1F", color: "white" }} onClick={save}>Save</Button>
                        <Button variant="contained" style={{ backgroundColor: "#FF5F1F", color: "white" }} onClick={() => window.print()}>Print / PDF</Button>
                    </Stack>
                </Toolbar>
            </AppBar>

            <Snackbar
                message="Saved !"
                open={snackOpen}
                autoHideDuration={1000}
                onClose={() => setSnackOpen(false)}
            />

            {isClicked &&
                <Restore
                    setIsClicked={setIsClicked}
                    isClicked={isClicked}
                    stored={stored}
                    chosenName={chosenName}
                />
            }
        </>
    )
}

export default Clock;